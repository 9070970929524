import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { AddUnderlyingShareholders } from 'components/AddUnderlyingShareholders';
import { AgendaItemRow } from 'components/AgendaItem';
import { FileUploadCard } from 'components/FileUploadCard';
import { useAuth } from 'hooks/useAuth';
import { useLocale } from 'hooks/useLocale';
import { useAgendaQuery } from 'queries/useAgendaQuery';
import { useMeetingQuery } from 'queries/useMeetingQuery';
import { useVoteQuery } from 'queries/useVote.Query';
import { useVoteMutation } from 'queries/useVoteMutation';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AgendaItem, Vote, Voting, getContentForLocale, isAllOptionsEmpty } from 'types/agenda';
import { OwnerType, UnderlyingShareholder } from 'types/attendee';
import { IMeeting, Type } from 'types/meeting';
import { getLocaleDateString, isDatePassed } from 'utils/date';

const VotePage = () => {
  const params = useParams();
  const meetingId = Number(params.meetingId);

  const { t } = useTranslation();
  const { locale } = useLocale();
  const { user } = useAuth();
  const { data: meeting, isLoading: isLoadingMeeting } = useMeetingQuery(meetingId, user?.personalNumber);
  const { data: agenda, isLoading: isLoadingAgenda, error: errorAgenda } = useAgendaQuery(meetingId);
  const { data: voting, isLoading: isLoadingVoting } = useVoteQuery(meetingId, meeting?.attendeeId);

  const voteMutation = useVoteMutation();

  const [votes, setVotes] = useState<Vote[]>(getInitialVotes(agenda?.agendaItems ?? []));
  const [ownerType, setOwnerType] = useState<OwnerType>(OwnerType.Owner);
  const [underlying, setUnderlying] = useState<UnderlyingShareholder[]>([]);

  useEffect(() => {
    if (meeting) {
      setOwnerType(meeting.ownerType === OwnerType.NotSet ? OwnerType.Owner : meeting.ownerType);
      setUnderlying(meeting.underlyingShareholders);
    }
  }, [meeting]);

  function getInitialVotes(agendaItems: AgendaItem[]): Vote[] {
    if (voting?.votes) {
      return voting.votes;
    }

    const votableAgendaItems = agendaItems.filter(
      agendaItem => !isAllOptionsEmpty(getContentForLocale(locale, agendaItem))
    );

    const votes = votableAgendaItems?.map(item => {
      return {
        id: item.agendaItemId,
        option: 'option3'
      };
    });

    return votes ?? [];
  }

  const cachedGetInitialVotes = useCallback(getInitialVotes, [voting, locale]);

  useEffect(() => {
    if (agenda?.agendaItems) {
      const cachedVotes = cachedGetInitialVotes(agenda.agendaItems);
      if (votes !== cachedVotes) {
        setVotes(cachedVotes);
      }
    }
    // eslint-disable-next-line
  }, [agenda, meeting, cachedGetInitialVotes, voting, locale]);

  const handleUpdateVotes = (agendaItemId: string, option: string) => {
    setVotes([...(votes?.filter(vote => vote.id !== agendaItemId) ?? []), { id: agendaItemId, option }]);
  };

  const handleUpdateVotesWithCheckbox = (option: string, votes: Vote[]) => {
    if (!votes) {
      return;
    }

    const isIndeterminate = votes.some(vote => vote.option === option);
    const isAllSelected = votes.every(vote => vote.option === option);

    if (isIndeterminate) {
      const votesWithUpdatedOption = votes.map(vote => {
        return { id: vote.id, option: option };
      });
      setVotes(votesWithUpdatedOption);
    }

    if (isAllSelected) {
      const votesWithUpdatedOption = votes.map(vote => {
        return { id: vote.id, option: 'option3' };
      });
      setVotes(votesWithUpdatedOption);
    }

    if (!isIndeterminate && !isAllSelected) {
      const votesWithUpdatedOption = votes.map(vote => {
        return { id: vote.id, option: option };
      });
      setVotes(votesWithUpdatedOption);
    }
  };

  function isCheckboxIndeterminate(votes: Vote[], option: string): boolean {
    return votes?.some(vote => vote.option === option) && !votes?.every(vote => vote.option === option);
  }

  function isCheckboxChecked(votes: Vote[], option: string): boolean {
    return votes?.every(vote => vote.option === option);
  }

  function getSelectedOption(agendaItemId: string): string {
    return votes?.find(vote => vote.id === agendaItemId)?.option ?? 'option3';
  }

  const submitVotes = () => {
    const voting: Voting = {
      givenName: user?.givenName as string,
      surName: user?.surName as string,
      attendeeId: meeting?.attendeeId ?? 0,
      ownerType: ownerType,
      isRepresentingOwnShares: (ownerType as string) !== OwnerType.Proxy_IsNotRepresentingOwnShares,
      pin: user?.personalNumber as string,
      underlyingShareholders: ownerType !== OwnerType.Owner ? underlying : [],
      votes
    };

    voteMutation.mutate({ voting, meetingId });
  };

  function renderFormHeading(meeting: IMeeting) {
    const type = getMeetingTypeText(meeting?.type);
    const date = getLocaleDateString(meeting.startTime, locale);

    return (
      <Stack>
        <Typography variant="h5" fontWeight={500}>
          {type} {t('registration_page.in')} {meeting.clientName}
        </Typography>
        <Typography variant="subtitle2">{date}</Typography>
      </Stack>
    );
  }

  function renderVotingSuccess() {
    if (voteMutation.isSuccess) {
      return <Alert severity="success">Successfully registered postal votes</Alert>;
    }
    return null;
  }

  function getMeetingTypeText(type: Type): string {
    if (type === Type.AGM) {
      return t('registration_page.annual_meeting');
    }

    return t('registration_page.extra_meeting');
  }

  if (!user) {
    return <Alert severity="error">You cannot use postal voting without signing in with BankID</Alert>;
  }

  if (errorAgenda) {
    return <Alert severity="error">{errorAgenda.message} - No agenda found</Alert>;
  }

  if (isLoadingMeeting || isLoadingAgenda) {
    return <CircularProgress />;
  }

  if (meeting?.lastPostalVotingDate && isDatePassed(meeting.lastPostalVotingDate)) {
    return <Alert severity="error">The deadline for postal voting has passed</Alert>;
  }

  if (!meeting) {
    return <Alert severity="error">Could not get meeting</Alert>;
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight={500}>
          {t('vote_page.postal_voting')}
        </Typography>
        <Typography variant="subtitle2">
          {t('vote_page.sub_heading', {
            lastPostalVotingDate: getLocaleDateString(meeting.lastPostalVotingDate, locale)
          })}
        </Typography>
        {renderFormHeading(meeting)}
        {renderVotingSuccess()}
      </Stack>

      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <InputLabel>{t('registration_page.iWannaRep')}</InputLabel>
            <Select
              size="small"
              value={ownerType}
              onChange={event => setOwnerType(event.target.value as OwnerType)}
              label={t('registration_page.iWannaRep')}
              fullWidth
            >
              <MenuItem key={OwnerType.Owner} value={OwnerType.Owner}>
                {t('registration_page.ownShares')}
              </MenuItem>
              <MenuItem
                key={OwnerType.Proxy_IsNotRepresentingOwnShares}
                value={OwnerType.Proxy_IsNotRepresentingOwnShares}
              >
                {t('registration_page.othersShares')}
              </MenuItem>
              <MenuItem key={OwnerType.Proxy} value={OwnerType.Proxy}>
                {t('registration_page.ownAndOthersShares')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {ownerType !== OwnerType.Owner && (
          <Grid container xs={12} sm={12} md={12}>
            <Grid xs={12}>
              <Typography variant="h6">{t('vote_page.shareholders_to_rep_header')}</Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <AddUnderlyingShareholders
                fields={underlying.map((x, i) => {
                  return { ...x, id: i.toString() };
                })}
                append={(newUnderlying: UnderlyingShareholder) => setUnderlying([...underlying, newUnderlying])}
                remove={(underlyingIndex: number) =>
                  setUnderlying([...underlying.filter((_, i) => i !== underlyingIndex)])
                }
                proxyId={meeting?.attendeeId ?? 0}
                loggedInPin={user?.personalNumber}
              />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <FileUploadCard
                meetingId={meetingId}
                meetingAttendeeId={meeting?.attendeeId ?? 0}
                pin={user.personalNumber}
              />
            </Grid>
            <Grid xs={12} sm={7} md={5}></Grid>
          </Grid>
        )}
        <Grid xs={12} sm={6} md={6}>
          <Typography variant="h6">{t('vote_page.postal_voting_items')}</Typography>
        </Grid>
        <Grid xs={12} sm={6} md={6} display={'flex'} justifyContent={'flex-end'}>
          <FormControlLabel
            label={t('vote_option.yes')}
            control={
              <Checkbox
                checked={isCheckboxChecked(votes, 'option1')}
                indeterminate={isCheckboxIndeterminate(votes, 'option1')}
                onClick={() => handleUpdateVotesWithCheckbox('option1', votes)}
              />
            }
          />
          <FormControlLabel
            label={t('vote_option.no')}
            control={
              <Checkbox
                checked={isCheckboxChecked(votes, 'option2')}
                indeterminate={isCheckboxIndeterminate(votes, 'option2')}
                onClick={() => handleUpdateVotesWithCheckbox('option2', votes)}
              />
            }
          />
          <FormControlLabel
            label={t('vote_option.no_instruction')}
            control={
              <Checkbox
                checked={isCheckboxChecked(votes, 'option3')}
                indeterminate={isCheckboxIndeterminate(votes, 'option3')}
                onClick={() => handleUpdateVotesWithCheckbox('option3', votes)}
              />
            }
          />
        </Grid>
        <Grid xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
        {!isLoadingVoting &&
          agenda?.isFinal &&
          agenda?.agendaItems.map(agendaItem => {
            return (
              <Fragment key={agendaItem.agendaItemId}>
                <AgendaItemRow
                  key={agendaItem.agendaItemId}
                  agendaItem={agendaItem}
                  selectedOption={getSelectedOption(agendaItem.agendaItemId)}
                  handleUpdateVotes={handleUpdateVotes}
                />
                <Grid xs={12} sx={{ p: 0 }}>
                  <Divider variant="fullWidth" />
                </Grid>
              </Fragment>
            );
          })}
        {!agenda?.isFinal && (
          <Typography variant="h6" fontWeight={500} sx={{ px: 4, py: 2 }}>
            {t('vote_page.noAgendaAvailable')}
          </Typography>
        )}
        <Grid xs={12}>
          <Button variant="contained" fullWidth onClick={submitVotes} disabled={!agenda?.isFinal}>
            {t('vote_page.send_postal_vote')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VotePage;
